const serviceData = [
  {
    id: 1,
    title: "Consultation on Finance",
    icon: "ri-map-pin-2-line",
    desc: "Our professionals provide financial services to clients based on their financial circumstances. ",
  },

  {
    id: 2,
    title: "Tracking Stocks",
    icon: "ri-bar-chart-grouped-fill",
    desc: "TInvested stocks are accurately noted and tracked till they yield a profit and are disbursed.",
  },

  {
    id: 3,
    title: "Investment Process",
    icon: "ri-medal-fill",
    desc: "Our professionals use your invested stocks to trade, earn gains, and distribute rewards to the firm and investors.",
  },

  {
    id: 4,
    title: "Strategy Development",
    icon: "ri-timer-flash-line",
    desc: "Our extremely intelligent professionals continue to improve as they discover new and safer ways to generate your profits.",
  },

  {
    id: 5,
    title: "Finance Analysis",
    icon: "ri-pie-chart-2-fill",
    desc: "In this business, we evaluate our viability, stability, and profitability. This enables us to make changes as needed.",
  },

  {
    id: 6,
    title: "Tax Advisory",
    icon: "ri-bar-chart-horizontal-line",
    desc: "Our tax counsel assists us in minimizing taxes while being compliant with the law.",
  },
];

export default serviceData;
