import React from "react";
import Helmet from "../../components/Helmet/Helmet";
import Sidebar from "../../components/UI/Sidebar";
import "../../styles/dashboard.css";
import Pricing from "../PricingInvest";

const InvestFunds = () => {
  return (
    <Helmet title="Invrst Funds">
      <div className="App">
        <div className="AppGlass">
          <Sidebar />
          <Pricing />
        </div>
      </div>
    </Helmet>
  );
};

export default InvestFunds;
