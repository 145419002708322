import React, { useEffect, useState } from "react";
import Helmet from "../../../../Helmet/Helmet";
import "../../MainDash.css";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../../../firebase";
import { useAuth } from "../../../../../contexts/AuthContext";

const Records = () => {
  const { currentUser } = useAuth();

  let dbStateRef = React.useRef();
  const [dbState, setDbState] = useState(dbStateRef.current);

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
      dbStateRef.current = doc.data();

      setDbState(dbStateRef.current);
    });

    return () => unsub;
    // eslint-disable-next-line jsx-a11y/alt-text, react-hooks/exhaustive-deps
  }, []);

  return (
    <Helmet title="Profit Records">
      <div className="MainDash">
        <h2 className="mt-20  ml-5">Your ROI History</h2>
      </div>
      <div className="p-12">
        <div className="relative overflow-x-auto  w-fill">
          <div className="pb-4 flex items-center"></div>
          <table className="w-full text-sm text-left text-gray-500 table-auto">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100">
              <tr>
                <th scope="col" className="px-6 py-3">
                  PLAN
                </th>
                <th scope="col" className="px-6 py-3">
                  AMOUNT
                </th>
                <th scope="col" className="px-6 py-3">
                  TYPE
                </th>
                <th scope="col" className="px-6 py-3">
                  DATE CREATED
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white border-b  ">
                <td className="px-6 py-4">{dbState?.roPlan}</td>
                <td className="px-6 py-4">{dbState?.roAmount}</td>
                <td className="px-6 py-4">{dbState?.roType}</td>
                <td className="px-6 py-4">{dbState?.roDate}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Helmet>
  );
};

export default Records;
