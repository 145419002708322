import React, { useEffect, useState } from "react";
import Helmet from "../../../../Helmet/Helmet";
import "../../MainDash.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../../../firebase";
import { useAuth } from "../../../../../contexts/AuthContext";
import Affiliate from "affiliate";

const Refer = () => {
  const { currentUser } = useAuth();

  let dbStateRef = React.useRef();

  const [dbState, setDbState] = useState(dbStateRef.current);

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
      dbStateRef.current = doc.data();

      setDbState(dbStateRef.current);
    });

    return () => unsub;
    // eslint-disable-next-line jsx-a11y/alt-text, react-hooks/exhaustive-deps
  }, []);

  let user = dbState?.userName;
  let ref = dbState?.referedBy;

  const aff = Affiliate.create({
    log: false,
    tags: [
      {
        hosts: [
          "grandvilleglobal.com/refRegister",
          "www.grandvilleglobal.com/refRegister",
        ],
        query: {
          ref: { user },
        },
      },
    ],
  });

  aff.attach();

  return (
    <Helmet title="Refer">
      <div className="MainDash">
        <h2 className="mt-20  ml-5">
          Refer users to Grandville Global Investment Limited community
        </h2>
      </div>
      <div className="p-12">
        <h3>Your Referrals</h3>
        <div className="relative overflow-x-auto  w-fill">
          <div className="pb-4 flex items-center">
            You can refer users by sharing your referral link:{" "}
            <CopyToClipboard
              text={`grandvilleglobal.com/refRegister?ref=${user}`}
              onCopy={() => alert("Copied")}
            >
              <span className="px-4 py-2 text-sm text-[#000D67] font-bold border border-gray-300 rounded-lg w-200">
                grandvilleglobal.com/refRegister?ref={user}
                <button className=" pl-20 text-red-400 ">Copy</button>
              </span>
            </CopyToClipboard>
          </div>
          <div className="pb-4 flex items-center">
            or your referral ID:
            <span className="px-4 py-2 text-sm text-[#000D67] font-bold rounded-lg w-50">
              {user}
            </span>{" "}
            You were referred by:
            <span className="px-4 py-2 text-sm text-[#000D67] font-bold rounded-lg w-50">
              {ref}
            </span>
          </div>
          <table className="w-full text-sm text-left text-gray-500 table-auto">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100">
              <tr>
                <th scope="col" className="px-6 py-3">
                  CLIENT NAME
                </th>
                <th scope="col" className="px-6 py-3">
                  REF.LEVEL
                </th>
                <th scope="col" className="px-6 py-3">
                  PARENT
                </th>
                <th scope="col" className="px-6 py-3">
                  CLIENT STATUS
                </th>
                <th scope="col" className="px-6 py-3">
                  DATE REGISTERED
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white border-b  ">
                <td className="px-6 py-4">{dbState?.refDate}</td>
                <td className="px-6 py-4">{dbState?.refLevel}</td>
                <td className="px-6 py-4">{dbState?.refParent}</td>
                <td className="px-6 py-4">{dbState?.refStatus}</td>
                <td className="px-6 py-4">{dbState?.refRegDate}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Helmet>
  );
};

export default Refer;
