import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../../styles/about-sectionTop.css";
import aboutImg from "../../assets/all-images/sales.png";

const AboutSection = ({ aboutClass }) => {
  return (
    <Container>
      <Row>
        <Col lg="6" md="6">
          <div className="about__section-content">
            <h4 className="section__subtitle">About Us</h4>
            <h2 className="section__title">
              6+ Years Providing Premium Finance Services
            </h2>
            <p className="section__description">
              We are an investment firm whose main purpose is to help businesses
              succeed by generating profits for our clients and assisting them
              in making sound financial decisions. We are a fantastic group of
              experts who manage the stocks invested and ensure their consistent
              development and returns. Grandville Global Investment Limited is
              our name. We help you realize your financial goals.
            </p>

            <div className="about__section-item d-flex align-items-center">
              <p className="section__description d-flex align-items-center gap-2">
                <i class="ri-checkbox-circle-line"></i> Regulated by VFSC
                government financial commission.
              </p>

              <p className="section__description d-flex align-items-center gap-2">
                <i class="ri-checkbox-circle-line"></i> Verified by Visa and
                Mastercard. All data is encrypted with strongest crptographic
                algorithms
              </p>
            </div>

            <div className="about__section-item d-flex align-items-center">
              <p className="section__description d-flex align-items-center gap-2">
                <i class="ri-checkbox-circle-line"></i> Real-time market data
                provided by leading analytical agencies and professionals
              </p>

              <p className="section__description d-flex align-items-center gap-2">
                <i class="ri-checkbox-circle-line"></i> Our trading platform is
                available on all devices for a better access and trading
                experience
              </p>
            </div>
          </div>
        </Col>

        <Col lg="6" md="6">
          <div className="about__img">
            <img src={aboutImg} alt="" className="w-100" />
            <h5>Susan Seacrest</h5>
            <h6>Grandville Global</h6>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutSection;
