import React, { Component } from "react";
import Card from "./CardUI";
import img1 from "../../assets/all-images/fincons.jpeg";
import img2 from "../../assets/all-images/stocks.jpeg";
import img3 from "../../assets/all-images/invprc.jpeg";
import img4 from "../../assets/all-images/strgdev.jpeg";
import img5 from "../../assets/all-images/finanal.jpeg";
import img6 from "../../assets/all-images/taxadv.jpeg";

class Cards extends Component {
  render() {
    return (
      <div className="container-fluid d-flex justify-content-center">
        <div className="row">
          <div className="col-md-4 pt-3 pb-3">
            <Card
              imgsrc={img1}
              title="Finance Consultation"
              text="Our professionals provide financial services to clients based on their financial circumstances.
"
            />
          </div>
          <div className="col-md-4 pt-3 pb-3">
            <Card
              imgsrc={img2}
              title="Tracking Stocks"
              text="Invested stocks are accurately noted and tracked till they yield a profit and are disbursed.
"
            />
          </div>
          <div className="col-md-4 pt-3 pb-3">
            <Card
              imgsrc={img3}
              title="Investment Process"
              text="Our professionals use your invested stocks to trade, earn gains, and distribute rewards to the firm and investors.
"
            />
          </div>
          <div className="col-md-4 pt-3 pb-3">
            <Card
              imgsrc={img4}
              title="Strategy Development"
              text="Our extremely intelligent professionals continue to improve as they discover new and safer ways to generate your profits.
"
            />
          </div>
          <div className="col-md-4 pt-3">
            <Card
              imgsrc={img5}
              title="Financial Analysis"
              text="In this business, we evaluate our viability, stability, and profitability. This enables us to make changes as needed.
"
            />
          </div>
          <div className="col-md-4 pt-3 pb-3">
            <Card
              imgsrc={img6}
              title="Tax Advisory"
              text="Our tax counsel assists us in minimizing taxes while being compliant with the law.
"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Cards;
