import React, { useState } from "react";
import Navbar from "../components/Header/Navbar";
import Footer from "../components/Footer/Footer";
import {
  Container,
  CssBaseline,
  Avatar,
  Typography,
  Button,
  Grid,
  makeStyles,
  Card,
  CardContent,
} from "@material-ui/core";
import { LockRounded } from "@material-ui/icons";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { useAuth } from "../contexts/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import { ScaleLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";
import Helmet from "../components/Helmet/Helmet";

const Login = (props) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();

  const override = `
        display: block;
        margin-left: 100px;
        border-color: red;
    `;
  const handleEmail = (event) => {
    setEmail(event.target.value);
  };
  const handlePassword = (event) => {
    setPassword(event.target.value);
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setLoad(true);
      await login(email, password);
      navigate("/dashboard");
    } catch (error) {
      toast.error(error.message);
    }
    setLoad(true);
    setLoading(false);
  };
  return (
    <Helmet tittle="Login">
      <Navbar />
      <section>
        <Container component="main" maxWidth="xs">
          <Card className={classes.card}>
            <CardContent>
              <ToastContainer />
              <CssBaseline />
              <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <LockRounded />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Login
                </Typography>
                <ValidatorForm
                  onSubmit={handleLogin}
                  onError={(errors) => {
                    for (const err of errors) {
                      console.log(err.props.errorMessages[0]);
                    }
                  }}
                  className={classes.form}
                >
                  <TextValidator
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Email"
                    onChange={handleEmail}
                    name="email"
                    value={email}
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      "this field is required",
                      "email is not valid",
                    ]}
                    autoComplete="off"
                  />
                  <TextValidator
                    variant="outlined"
                    fullWidth
                    label="Password"
                    onChange={handlePassword}
                    name="password"
                    type="password"
                    value={password}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                    autoComplete="off"
                  />

                  {loading ? (
                    <ScaleLoader
                      css={override}
                      size={150}
                      color={"#8360c3"}
                      loading={loading}
                    />
                  ) : (
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      className={classes.submit}
                      disabled={load}
                    >
                      Sign In
                    </Button>
                  )}

                  <Grid container>
                    <Grid item>
                      <Link
                        to="/register"
                        onClick={props.toggle}
                        className={classes.pointer}
                        variant="body2"
                      >
                        {"Don't have an account? Sign Up"}
                      </Link>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item>
                      <Link
                        to="/reset-password"
                        onClick={props.toggle}
                        className={classes.pointer}
                        variant="body2"
                      >
                        {"Forgot Password? Reset"}
                      </Link>
                    </Grid>
                  </Grid>
                </ValidatorForm>
              </div>
            </CardContent>
          </Card>
        </Container>
      </section>
      <Footer />
    </Helmet>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#8360c3",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    background: "linear-gradient(45deg, #2ebf91 30%, #8360c3 90%)",
    margin: theme.spacing(3, 0, 2),
    color: "#fff",
  },
  card: {
    marginTop: "60px",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "20px",
  },
  pointer: {
    cursor: "pointer",
    color: "red",
  },
}));
export default Login;
