import React from "react";
import "../../styles/start.css";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import moneyImg from "../../assets/all-images/about-img.png";

const Start = () => {
  return (
    <section className="become__driver">
      <Container>
        <Row>
          <Col lg="6" md="6" sm="12" className="become__driver-img">
            <img src={moneyImg} alt="" className="w-100" />
          </Col>

          <Col lg="6" md="6" sm="12">
            <h2 className="section__title become__driver-title">
              Our Experts will be waiting to work with you.{" "}
            </h2>
            <Link to="/register">
              <button className="btnst become__driver-btn mt-4">
                Get Started Today
              </button>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Start;
