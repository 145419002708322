import "./widget.scss";
import React, { useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../../firebase";
import { useAuth } from "../../../../contexts/AuthContext";
const Widget = ({ type }) => {
  const { currentUser } = useAuth();

  let data;

  let dbStateRef = React.useRef();

  const [dbState, setDbState] = useState(dbStateRef.current);

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
      dbStateRef.current = doc.data();

      setDbState(dbStateRef.current);
    });

    return () => unsub;
    // eslint-disable-next-line jsx-a11y/alt-text, react-hooks/exhaustive-deps
  }, []);

  let accountBal = dbState?.accountBalance;

  data = {
    title: "Account Balance",
    isBalance: true,

    icon: (
      // eslint-disable-next-line jsx-a11y/alt-text
      <img src="https://img.icons8.com/external-flat-gradient-andi-nur-abdillah/40/000000/external-balance-finance-flat-gradient-flat-gradient-andi-nur-abdillah.png" />
    ),
  };

  return (
    <>
      <div className="widget">
        <div className="left">
          <span className="title">{data.title}</span>
          <span className="counter">
            {data.isBalance && "$"} {accountBal}
          </span>
          <span className="link">{data.link}</span>
        </div>
        <div className="right">
          <div className="percentage positive"></div>
          {data.icon}
        </div>
      </div>
    </>
  );
};

export default Widget;
