import React, { useState, useEffect } from "react";
import axios from "axios";
import { Sparklines, SparklinesLine } from "react-sparklines";
import { FaTwitter, FaFacebook, FaReddit, FaGithub } from "react-icons/fa";
import DOMPurify from "dompurify";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const CoinPage = () => {
  const [coin, setCoin] = useState({});
  const params = useParams();
  const navigate = useNavigate();

  const url = `https://api.coingecko.com/api/v3/coins/${params.coinId}?localization=false&sparkline=true`;

  useEffect(() => {
    axios.get(url).then((response) => {
      setCoin(response.data);
      console.log(response.data);
    });
  }, [url]);

  return (
    <div className="rounded-div my-12 py-8">
      <button
        type="button"
        onClick={() => navigate("/dashboard")}
        className="  w-full py-6 flex items-center text-xl"
      >
        {" "}
        <svg
          className="mr-2"
          width="10"
          height="12"
          viewBox="0 0 10 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.369216 4.869C0.425718 4.811 0.639065 4.563 0.837798 4.359C2.00292 3.076 5.04238 0.976 6.63322 0.335C6.87482 0.232 7.48563 0.014 7.81198 0C8.1247 0 8.4228 0.072 8.70726 0.218C9.06186 0.422 9.34632 0.743 9.50219 1.122C9.60253 1.385 9.7584 2.172 9.7584 2.186C9.91427 3.047 10 4.446 10 5.992C10 7.465 9.91427 8.807 9.78665 9.681C9.77204 9.695 9.61617 10.673 9.44569 11.008C9.13298 11.62 8.52216 12 7.86848 12H7.81198C7.38626 11.985 6.49099 11.605 6.49099 11.591C4.98587 10.949 2.01656 8.952 0.823186 7.625C0.823186 7.625 0.487092 7.284 0.340964 7.071C0.113005 6.765 0 6.386 0 6.007C0 5.584 0.127618 5.19 0.369216 4.869"
            fill="#3754A5"
          />
        </svg>
        Go back
      </button>
      <div className="flex py-8">
        <img className="w-20 mr-8" src={coin.image?.large} alt="/" />
        <div>
          <p className="text-3xl font-bold">{coin?.name} price</p>
          <p>({coin.symbol?.toUpperCase()} / USD)</p>
        </div>
      </div>
      <div className=" w-full  px-2 py-6">
        <div className="grid md:grid-cols-2 gap-8">
          <div>
            <div className="flex justify-between">
              {coin.market_data?.current_price ? (
                <p className="text-3xl font-bold">
                  ${coin.market_data.current_price.usd.toLocaleString()}
                </p>
              ) : null}
              <p>7 Day</p>
            </div>
            <div>
              <Sparklines data={coin.market_data?.sparkline_7d.price}>
                <SparklinesLine color="teal" />
              </Sparklines>
            </div>
            <div className="flex justify-between py-4">
              <div>
                <p className="text-gray-500 text-sm">Market Cap</p>
                {coin.market_data?.market_cap ? (
                  <p>${coin.market_data.market_cap.usd.toLocaleString()}</p>
                ) : null}
              </div>
              <div>
                <p className="text-gray-500 text-sm">Volume (24h)</p>
                {coin.market_data?.market_cap ? (
                  <p>${coin.market_data.total_volume.usd.toLocaleString()}</p>
                ) : null}
              </div>
            </div>

            <div className="flex justify-between py-4">
              <div>
                <p className="text-gray-500 text-sm">24h High</p>
                {coin.market_data?.high_24h ? (
                  <p>${coin.market_data.high_24h.usd.toLocaleString()}</p>
                ) : null}
              </div>
              <div>
                <p className="text-gray-500 text-sm">24h Low</p>
                {coin.market_data?.low_24h ? (
                  <p>${coin.market_data.low_24h.usd.toLocaleString()}</p>
                ) : null}
              </div>
            </div>
          </div>

          <div>
            <p className="text-xl font-bold">Market Stats</p>
            <div className="flex justify-between py-4">
              <div>
                <p className="text-gray-500 text-sm">Market Rank</p>
                {coin.market_cap_rank}
              </div>
              <div>
                <p className="text-gray-500 text-sm">Hashing Algorithm</p>
                {coin.hashing_algorithm ? (
                  <p>{coin.hashing_algorithm}</p>
                ) : null}
              </div>
              <div>
                <p className="text-gray-500 text-sm">Trust Score</p>
                {coin.tickers ? <p>{coin.liquidity_score.toFixed(2)}</p> : null}
              </div>
            </div>

            <div className="flex justify-between py-4">
              <div>
                <p className="text-gray-500 text-sm">Price Change (24h)</p>
                {coin.market_data ? (
                  <p>
                    {coin.market_data.price_change_percentage_24h.toFixed(2)}%
                  </p>
                ) : null}
              </div>
              <div>
                <p className="text-gray-500 text-sm">Price Change (7d)</p>
                {coin.market_data ? (
                  <p>
                    {coin.market_data.price_change_percentage_7d.toFixed(2)}%
                  </p>
                ) : null}
              </div>
              <div>
                <p className="text-gray-500 text-sm">Price Change (14d)</p>
                {coin.market_data ? (
                  <p>
                    {coin.market_data.price_change_percentage_14d.toFixed(2)}%
                  </p>
                ) : null}
              </div>
            </div>
            <div className="flex justify-between py-4">
              <div>
                <p className="text-gray-500 text-sm">Price Change (30d)</p>
                {coin.market_data ? (
                  <p>
                    {coin.market_data.price_change_percentage_30d.toFixed(2)}%
                  </p>
                ) : null}
              </div>
              <div>
                <p className="text-gray-500 text-sm">Price Change (60d)</p>
                {coin.market_data ? (
                  <p>
                    {coin.market_data.price_change_percentage_60d.toFixed(2)}%
                  </p>
                ) : null}
              </div>
              <div>
                <p className="text-gray-500 text-sm">Price Change (1y)</p>
                {coin.market_data ? (
                  <p>
                    {coin.market_data.price_change_percentage_1y.toFixed(2)}%
                  </p>
                ) : null}
              </div>
            </div>
            <div className="flex justify-around p-8 text-accent">
              <FaTwitter />
              <FaFacebook />
              <FaReddit />
              <FaGithub />
            </div>
          </div>
        </div>

        {/* Description */}
        <div className="py-4">
          <p className="text-xl font-bold">About {coin.name}</p>
          <p
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                coin.description ? coin.description.en : ""
              ),
            }}
          ></p>
        </div>
      </div>
    </div>
  );
};

export default CoinPage;
