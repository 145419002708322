/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "../../styles/sidebar.css";
import { UilSignOutAlt } from "@iconscout/react-unicons";
import { SidebarData } from "./Data";
import { UilBars } from "@iconscout/react-unicons";
import { motion } from "framer-motion";
import { BrandLogo } from "../brandLogo";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const Sidebar = () => {
  const [selected, setSelected] = useState(0);

  const [expanded, setExpaned] = useState(true);

  const sidebarVariants = {
    true: {
      left: "0",
    },
    false: {
      left: "-60%",
    },
  };

  function handleClick(index, item) {
    setSelected(index);
  }
  const [error, setError] = useState("");

  const { logout } = useAuth();

  const navigate = useNavigate();

  async function handleLogout() {
    setError("");

    try {
      await logout();
      navigate("/Login");
    } catch {
      setError("Failed to log out");
    }
  }

  return (
    <>
      <div
        className="bars"
        style={expanded ? { left: "60%" } : { left: "5%" }}
        onClick={() => setExpaned(!expanded)}
      >
        <UilBars />
      </div>
      <motion.div
        className="sidebar"
        variants={sidebarVariants}
        animate={window.innerWidth <= 768 ? `${expanded}` : ""}
      >
        {/* logo */}
        <div className="logos ml-3 ">
          <BrandLogo />
        </div>

        <div className="menuTop">
          {SidebarData.map((item, index) => (
            <div
              className={
                selected === index ? "menuItemTop active" : "menuItemTop"
              }
              key={index}
              onClick={() => {
                handleClick(index);
                navigate(item.path);
              }}
            >
              <item.icon onClick={() => navigate(item.path)} />

              {item.heading}
            </div>
          ))}
          {/* signoutIcon */}
          <div className="pl-10">
            <button className="btnlgt" onClick={handleLogout}>
              <UilSignOutAlt />
              <span>logout</span>
            </button>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Sidebar;
