import React from "react";
import BackTopBtn from "../components/BackTopbtn";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Header/Navbar";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import Start from "../components/UI/GetStarted";
import Card from "./Cards/Cards";

const Services = () => {
  return (
    <Helmet title="Services">
      <Navbar />
      <CommonSection title="Services" />
      <div className="w-full bg-white py-16 px-4">
        <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
          <h3 className=" text-[#F9A827] mx-auto my-4">OUR PREMIUM SERVICES</h3>
          <div className="flex flex-col justify-center">
            <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
              WHAT WE OFFER
            </h1>
            <p>
              Our guarantees protect investments from non-commercial risks and
              can help financial backers acquire access to subsidizing sources
              with pre-negotiated financial terms. Please see the list below for
              our services.
            </p>
          </div>
        </div>
        <Card />
        <Start />
      </div>
      <Footer />
      <BackTopBtn />
    </Helmet>
  );
};

export default Services;
