import React, { Fragment, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { Dialog, Transition } from "@headlessui/react";
import "../../MainDash.css";
import Helmet from "../../../../Helmet/Helmet";

const Deposits = () => {
  const [transaction, setTransaction] = useState("");

  const prefix = "$ ";
  const [value, setValue] = useState(0);

  const handleChange = (e) => {
    e.preventDefault();
    const { value = "" } = e.target;
    const parsedValue = value.replace(/[^\d.]/gi, "");
    setValue(parsedValue);
  };

  const handleOnBlur = () => setValue(Number(value).toFixed(2));

  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const areAllFieldsFilled = transaction !== "" && value !== "";

  return (
    <Helmet title="Deposit">
      <div className="MainDash">
        <h2 className="mt-20  ml-5">Fund your Account</h2>
      </div>
      <div class="min-h-screen  flex flex-col justify-center py-12 px-6 lg:px-8">
        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div class="bg-white py-8 px-8 shadow rounded-lg sm:px-10">
            <p className="text-gray-500 font-weight: 100">
              Please Note that Minimum Fund Amount is the amount of the package
              you want to invest in or its equivalent. Contact
              support@grandvilleglobal.com for a more detailed description. Your
              account will be credited once your payment is confirmed.{" "}
            </p>
            <p className="text-[#000D67] font-weight: 100">
              Copy the wallet address provided below. Make payment from your
              respective crypto wallet. (Send the equivalent crypto amount of
              the amount you wish to invest). Provide your transaction hash ID.
              This can be gotten from your wallet after payment. Click on Fund.
            </p>
            <p className="text-gray-500 font-weight: 100">
              Your account will be credited once your payment is confirmed.
              NOTE: If you have problems finding your Transaction Hash ID, Use
              the last 10 digits of your wallet address as the Transaction Hash
              ID. If you encounter any issue while funding your account, please
              contact: support@grandvilleglobal.com for assistance.
            </p>
            <form class="mb-0 space-y-8">
              <div>
                <label
                  for="fundCode"
                  class="block text-sm font-medium text-[#000D67]"
                >
                  Fund Code
                </label>
                <div class="mt-1">
                  <input
                    id="fundCode"
                    name="fundCode"
                    type="fundCode"
                    placeholder="XNKuZ$A3N2S6sgvN"
                    disabled
                    required
                    className="px-4 py-2 text-lg font-medium border border-gray-300 rounded-lg w-full  "
                  />
                </div>
              </div>

              <div>
                <label
                  for="amount"
                  class="block text-sm font-medium text-[#000D67]"
                >
                  Amount
                </label>

                <div class="mt-1">
                  <CurrencyInput
                    prefix={prefix}
                    name="currencyInput"
                    id="currencyInput"
                    data-number-to-fixed="2"
                    data-number-stepfactor="100"
                    value={value}
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleOnBlur}
                    allowDecimals
                    decimalsLimit="2"
                    disableAbbreviations
                    required
                    className="px-4 py-2 text-lg font-medium border border-gray-300 rounded-lg w-full  "
                  />
                </div>
              </div>

              <div>
                <label
                  for="fundCode"
                  class="block text-sm font-medium text-[#000D67]"
                >
                  Bitcoin Address
                </label>
                <div class="mt-1">
                  <span className="px-4 py-2 mt-2 text-sm text-[#000D67] font-bold border border-gray-300 rounded-lg w-full">
                    bc1q69u3ep3snweyschqjr3f0lhe0znewn4tunclja
                  </span>
                </div>
              </div>

              <div>
                <label
                  for="fundCode"
                  class="block text-sm font-medium text-[#000D67]"
                >
                  USDT Address (TRC20)
                </label>
                <div class="mt-1">
                  <span className="px-4 py-2 mt-2 text-sm text-[#000D67] font-bold border border-gray-300 rounded-lg w-full">
                    TDfKtuo5GM4a8bUJekapojLxszfaJh4pTJ
                  </span>
                </div>
              </div>

              <div>
                <label
                  for="fundCode"
                  class="block text-sm font-medium text-[#000D67]"
                >
                  Ethereum Address
                </label>
                <div class="mt-1">
                  <span className="px-4 py-2 text-sm text-[#000D67] font-bold border border-gray-300 rounded-lg w-full">
                    0xdE383037372Adba01f6eccEfA06769bD85F92B2A
                  </span>
                </div>
              </div>

              <div>
                <label
                  for="fundCode"
                  class="block text-sm font-medium text-[#000D67]"
                >
                  LiteCoin Address
                </label>
                <div class="mt-1">
                  <span className="px-4 py-2 text-sm text-[#000D67] font-bold border border-gray-300 rounded-lg w-full  ">
                    ltc1q9detxz86mdw05ytypdd2s2s23rdjse6yxvhj3z
                  </span>
                </div>
              </div>

              <div>
                <label
                  for="password"
                  class="block text-sm font-medium text-[#000D67]"
                >
                  Transaction HashID
                </label>
                <div class="mt-1">
                  <input
                    id="transaction"
                    name="transaction"
                    type="transaction"
                    value={transaction}
                    onChange={(e) => setTransaction(e.target.value)}
                    required
                    className="px-4 py-2 text-sm text-[#000D67] font-bold border border-gray-300 rounded-lg w-full  "
                  />
                </div>
              </div>

              {/* <div>
                <label
                  for="company-size"
                  class="block text-sm font-medium text-gray-700"
                >
                  Company size
                </label>
                <div class="mt-1">
                  <select
                    name="company-size"
                    id="company-size"
                    required
                    class="w-full border-gray-300 rounded-lg shadow-sm"
                  >
                    <option value="">Please select</option>
                    <option value="small">Bitcoin</option>
                    <option value="medium">Ethereum</option>
                    <option value="large">LiteCoin</option>
                  </select>
                </div>
              </div> */}
              <div>
                <button
                  type="button"
                  onClick={openModal}
                  disabled={!areAllFieldsFilled}
                  class="w-full cursor-not-allowed  flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  FUND
                </button>
              </div>

              <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                  </Transition.Child>

                  <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Dialog.Panel className="w-2/3 max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                          >
                            Payment Processing
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Your payment is processing updates will appear on
                              your dashbaord shortly. Any delay on dashboard
                              contact support with proof of payment
                            </p>
                          </div>

                          <div className="mt-4">
                            <button
                              type="button"
                              className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                              onClick={closeModal}
                            >
                              Got it, thanks!
                            </button>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition>
            </form>
          </div>
        </div>
      </div>
    </Helmet>
  );
};

export default Deposits;
