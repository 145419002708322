import React from "react";
import { Container } from "reactstrap";
import BackTopBtn from "../components/BackTopbtn";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Header/Navbar";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";

const Faq = () => {
  return (
    <Helmet title="FAQ">
      <Navbar />
      <CommonSection title="FAQ" />
      <Container>
        <h4 className="section__subtitle">OUR FREQUENTLY ASKED QUESTIONS</h4>
        <h2 className="section__title">Do You Have Any Questions?</h2>
        <p className="section__description">
          This page contains the most well-known inquiries from clients. In the
          event that the individual can't observe the solution to the inquiry
          he's keen on, he can allude to the task's Customer support. The
          solicitations are handled nonstop.
        </p>
      </Container>
      <Footer />
      <BackTopBtn />
    </Helmet>
  );
};

export default Faq;
