import React from "react";
import Helmet from "../../components/Helmet/Helmet";
import Deposit from "../../components/UI/MainDash/widgets/pages/deposits";
import Sidebar from "../../components/UI/Sidebar";
import "../../styles/dashboard.css";

const ProfitRecord = () => {
  return (
    <Helmet title="Deposit Funds">
      <div className="App">
        <div className="AppGlass">
          <Sidebar />
          <section>
            <Deposit />
          </section>
        </div>
      </div>
    </Helmet>
  );
};

export default ProfitRecord;
