import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import NotFound from "../pages/NotFound";
import Contact from "../pages/Contact";
import Services from "../pages/Services";
import Pricing from "../pages/Pricing";
import Faq from "../pages/Faq";
import DashBoard from "../pages/User/DashBoard";
import CoinPage from "../components/UI/MainDash/CoinPage";
import ProfitRecord from "../pages/User/ProfitRecord";
import TransactionHistory from "../pages/User/TransactionHistory";
import DepositFund from "../pages/User/DepositFund";
import InvestFund from "../pages/User/InvestFunds";

import PrivateRoute from "../Authentication/PrivateRoute";
import { AuthProvider } from "../contexts/AuthContext";
import Signup from "../Authentication/Signup";
import Login from "../Authentication/Login";
import WithdrawFunds from "../pages/User/WithdrawFunds";
import Referral from "../pages/User/Referral";
import RefSignup from "../Authentication/RefSignup";
import ResetPasssword from "../Authentication/ResetPasssword";
import PasswordReset from "../Authentication/PasswordRes";

const Routers = () => {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/register" element={<Signup />} />
          <Route path="/refregister" element={<RefSignup />} />

          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<DashBoard />} />
          <Route path="/coin/:coinId" element={<CoinPage />} />
          <Route path=":coinId" />
          <Route path="/profitRecords" element={<ProfitRecord />} />
          <Route path="/transactionHistory" element={<TransactionHistory />} />
          <Route path="/deposit" element={<DepositFund />} />
          <Route path="/invest" element={<InvestFund />} />
          <Route path="/refer" element={<Referral />} />
          <Route path="/reset-password" element={<ResetPasssword />} />
          <Route path="/password-reset" element={<PasswordReset />} />

          <Route path="/withdraw" element={<WithdrawFunds />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default Routers;
