import React from "react";
import AccountBalance from "../widgets/AccountBalance";
import ActiveInvestmentPlans from "../widgets/ActiveInvestmentPlans";
import TotalBonus from "../widgets/TotalBonus";
import TotalDeposit from "../widgets/TotalDeposit";
import TotalInvestmentPlans from "../widgets/TotalInvestmentPlans";
import TotalProfit from "../widgets/TotalProfit";
import TotalReferralBonus from "../widgets/TotalReferralBonus";
import TotalWithdrawals from "../widgets/TotalWithdrawals";

const Cards = () => {
  return (
    <div className="container-fluid d-flex justify-content-center">
      <div className="row">
        <div className="col-md-4 pt-3 pb-3">
          <AccountBalance type="accountBalance" />
        </div>
        <div className="col-md-4 pt-3 pb-3">
          <ActiveInvestmentPlans type="activeInvestmentPlans" />
        </div>
        <div className="col-md-4 pt-3 pb-3">
          <TotalBonus type="totalBonus" />
        </div>
        <div className="col-md-4 pt-3 pb-3">
          <TotalDeposit type="totalDeposit" />
        </div>
        <div className="col-md-4 pt-3 pb-3">
          <TotalInvestmentPlans type="totalIvestmentPlans" />
        </div>
        <div className="col-md-4 pt-3 pb-3">
          <TotalProfit type="totalProfit" />
        </div>{" "}
        <div className="col-md-4 pt-3 pb-3">
          <TotalReferralBonus type="totalReferralBonus" />
        </div>
        <div className="col-md-4 pt-3 pb-3">
          <TotalWithdrawals type="totalWithdrawals" />
        </div>
      </div>
    </div>
  );
};

export default Cards;
