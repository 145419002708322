import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Pricing = ({
  data,
  price,
  duration,
  background,
  shadow = "#a0c5fa",
  buttonContent,
  currency = "$",
  subTitle,
  priceText,
  headerText,
}) => {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const { currentUser } = useAuth();

  let dbStateRef = React.useRef();

  const [dbState, setDbState] = useState(dbStateRef.current);

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
      dbStateRef.current = doc.data();

      setDbState(dbStateRef.current);
    });

    return () => unsub;
    // eslint-disable-next-line jsx-a11y/alt-text, react-hooks/exhaustive-deps
  }, []);

  let accountBal = dbState?.accountBalance;

  const beginnerPlan = 300;
  const basicPlan = 5000;
  const professionalPlan = 10000;
  const grandPlan = 25000;
  const masterPlan = 50000;
  const diamondPlan = 100000;
  const standardPlan = 3000;
  const professional = 7000;
  const premium = 15000;
  const elite = 30000;
  const deluxe = 50000;

  const handleButtonClick = () => {
    const plans = [
      beginnerPlan,
      basicPlan,
      professionalPlan,
      grandPlan,
      masterPlan,
      diamondPlan,
      standardPlan,
      professional,
      premium,
      elite,
      deluxe,
    ];
    let successMessageDisplayed = false;
    plans.sort((a, b) => a - b); // sort the plans array in ascending order

    if (accountBal <= 0) {
      toast.error(
        "Your account balance is low. Please deposit funds to invest.",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    } else {
      for (let i = 0; i < plans.length; i++) {
        if (accountBal < plans[i] && !successMessageDisplayed) {
          toast.warning(
            "Your account balance is low. Please deposit more funds.",
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
          break;
        } else if (accountBal >= plans[i] && !successMessageDisplayed) {
          setIsOpen(true);

          toast.success("Processing", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          successMessageDisplayed = true;
          break;
        }
      }
    }
  };

  return (
    <div className="container-fluid d-flex justify-content-center">
      <div className="row">
        <MainContainer shadow={shadow}>
          <Header background={background}>{headerText}</Header>
          {price !== undefined && (
            <PricingContainer>
              <PriceContainer>
                <CurrencyContainer>
                  <span>{currency}</span>
                </CurrencyContainer>
                <Price>
                  <span>{price}</span>
                </Price>
                {price > 0 && (
                  <Duration>
                    <span> {duration === "Weekly" ? "Weekly" : "Daily"}</span>
                  </Duration>
                )}
              </PriceContainer>
              {subTitle && (
                <SubTitle>
                  <h4>{subTitle}</h4>
                </SubTitle>
              )}
              {priceText && (
                <PriceText>
                  <h5>{priceText}</h5>
                </PriceText>
              )}
            </PricingContainer>
          )}

          {data && (
            <DataContainer>
              <ul>
                {data.map((dt, index) => (
                  <li key={index}>
                    {dt.value ? (
                      <FaCheck className="true" />
                    ) : (
                      <ImCross className="false" />
                    )}
                    {dt.text}
                  </li>
                ))}
              </ul>
              {buttonContent && (
                <ButtonContainer>
                  <Button onClick={handleButtonClick}>{buttonContent}</Button>
                  <ToastContainer />
                </ButtonContainer>
              )}
            </DataContainer>
          )}
        </MainContainer>
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-2/3 max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Investment Processing
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        You have successfully selected this investment plan
                      </p>
                    </div>

                    <div className="mt-4">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={closeModal}
                      >
                        Got it, thanks!
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    </div>
  );
};

const MainContainer = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");
  font-family: "Raleway", sans-serif;
  width: 20rem;
  min-height: 40rem;
  height: max-content;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  color: #1d3557;
  box-shadow: 0 8px 14px -6px ${(props) => props.shadow};
  transition: 0.4s ease-in-out;
  &:hover {
    box-shadow: 0 8px 26px -6px ${(props) => props.shadow};
    margin-bottom: 0.4rem;
  }
`;

const Header = styled.div`
  margin: 0.6rem;
  height: 4rem;
  background-color: #ebf3fd;
  background-image: ${(props) => props.background};
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: larger;
  font-weight: 600;
`;

const PricingContainer = styled.div`
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 3rem;
`;

const PriceContainer = styled.div`
  display: flex;
`;

const CurrencyContainer = styled.div`
  margin-top: 0.5rem;
  margin-right: 0.2rem;
`;

const Price = styled.div`
  span {
    font-size: 3rem;
  }
`;

const Duration = styled.div`
  margin-top: 2rem;
`;

const SubTitle = styled.div`
  text-transform: uppercase;
  text-align: center;
  margin: 0.4rem 0 1.3rem 0;
`;

const PriceText = styled.div`
  display: flex;
  justify-content: center;
  width: 60%;
  text-align: center;
  font-weight: 100;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border-radius: 0.3rem;
  border: 0.1rem solid #1d3557;
  width: 90%;
  height: 3.5rem;
  background-color: transparent;
  font-size: 1.2rem;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  &:hover {
    background-color: #1d3557;
    color: white;
  }
`;

const DataContainer = styled.div`
  ul {
    list-style-type: none;
    li {
      display: flex;
      align-items: center;
      .true {
        color: #34f034;
        font-size: 1rem;
      }
      .false {
        color: #f54343;
      }
      svg {
        margin-right: 0.5rem;
        font-size: 0.8rem;
      }
      margin-bottom: 1rem;
    }
  }
`;

Pricing.propTypes = {
  data: PropTypes.array.isRequired,
  price: PropTypes.number.isRequired,
  duration: PropTypes.oneOf(["y", "m"]).isRequired,
  buttonContent: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
};

export default Pricing;
