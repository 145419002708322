import React from "react";

import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/footer.css";
import FooterLogo from "../../assets/all-images/logoImages/grandville-purplebg-whitefg-text-right.png";

const quickLinks = [
  {
    path: "/home",
    display: "HOME",
  },
  {
    path: "/about",
    display: "ABOUT",
  },
  {
    path: "/services",
    display: "SERVICES",
  },
  {
    path: "/pricing",
    display: "PRICING",
  },

  {
    path: "/faq",
    display: "FAQ",
  },
  {
    path: "/register",
    display: "REGISTER",
  },
  {
    path: "/login",
    display: "LOGIN",
  },
  {
    path: "/contact",
    display: "CONTACT",
  },
  {
    path: "/contact",
    display: "PRIVACY POLICY",
  },
];

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="4" md="4" sm="12">
            <div className="logo footer__logo">
              <h1>
                <Link to="/home" className=" d-flex align-items-center gap-2">
                  <img src={FooterLogo} alt="footerlogo" />
                </Link>
              </h1>
            </div>
            <p className="footer__logo-content">
              We are an investment firm whose main purpose is to help businesses
              succeed by generating profits for our customers and assisting them
              in making sound financial decisions.
            </p>
          </Col>

          <Col lg="2" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title">Quick Links</h5>
              <ListGroup>
                {quickLinks.map((item, index) => (
                  <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                    <Link to={item.path}>{item.display}</Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>

          <Col lg="3" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title mb-4">
                {" "}
                <i class="ri-home-line"></i>Head Office
              </h5>
              <p className="office__info">
                {" "}
                Landstrasse 23 Bankenviertel, Frankfurt, Germany
              </p>
              <p className="office__info">Phone: +49 211 5684962</p>

              <p className="office__info">
                Email: support@grandvilleglobal.com
              </p>

              <p className="office__info">Office Time: 10:00 - 17:00</p>
              <span className="office__info">
                <i class="ri-twitter-fill"></i>
              </span>
              <span className="office__info">
                <i class="ri-telegram-fill"></i>
              </span>
              <span className="office__info">
                <i class="ri-linkedin-box-fill"></i>
              </span>
              <span className="office__info">
                <i class="ri-instagram-fill"></i>
              </span>
              <span className="office__info">
                <i class="ri-pinterest-fill"></i>
              </span>
            </div>
          </Col>

          <Col lg="3" md="4" sm="12">
            <div className="mb-4">
              <h5 className="footer__link-title">Newsletter</h5>
              <p className="section__description">Subscribe our newsletter</p>
              <div className="newsletter">
                <input type="email" placeholder="Email" />
                <span>
                  <i class="ri-send-plane-line"></i>
                </span>
              </div>
            </div>
          </Col>

          <Col lg="12">
            <div className="footer__bottom">
              <p className="section__description d-flex align-items-center justify-content-center gap-1 pt-4">
                <i class="ri-copyright-line"></i>Copyright {year}, Grandville
                Global Investment Limited.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
