import React from "react";
import Helmet from "../../components/Helmet/Helmet";
import Records from "../../components/UI/MainDash/widgets/pages/records";
import Sidebar from "../../components/UI/Sidebar";
import "../../styles/dashboard.css";

const ProfitRecord = () => {
  return (
    <Helmet title="Profit Record">
      <div className="App">
        <div className="AppGlass">
          <Sidebar />
          <section>
            <Records />
          </section>
        </div>
      </div>
    </Helmet>
  );
};

export default ProfitRecord;
