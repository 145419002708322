import React, { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import Helmet from "../../../../Helmet/Helmet";
import "../../MainDash.css";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../../../firebase";
import { useAuth } from "../../../../../contexts/AuthContext";

const TransactionHistory = () => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const { currentUser } = useAuth();

  let dbStateRef = React.useRef();
  const [dbState, setDbState] = useState(dbStateRef.current);

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
      dbStateRef.current = doc.data();

      setDbState(dbStateRef.current);
    });

    return () => unsub;
    // eslint-disable-next-line jsx-a11y/alt-text, react-hooks/exhaustive-deps
  }, []);
  return (
    <Helmet title="Transaction History">
      <div className="MainDash">
        <h2 className="mt-20 ml-5">Transactions On Your Account</h2>
      </div>
      <div className="w-full px-2 ">
        <Tab.Group>
          <Tab.List className="flex space-x-1 p-1">
            <Tab
              className={({ selected }) =>
                classNames(
                  "rounded-lg px-5 pl-5 pr-5  text-sm font-bold leading-5 text-[#3754A5]",
                  "ring-[#FBC1BF] ring-opacity-60 ring-offset-2  focus:outline-none focus:ring-0",
                  selected ? "bg-[#FBC1BF] font-semibold" : "text-blue-200"
                )
              }
            >
              DEPOSITS
            </Tab>

            <Tab
              className={({ selected }) =>
                classNames(
                  "rounded-lg py-2.5 pl-5 pr-5 text-sm font-bold leading-5 text-[#3754A5]",
                  "ring-[#FBC1BF] ring-opacity-60 ring-offset-2  focus:outline-none focus:ring-0",
                  selected ? "bg-[#FBC1BF] font-semibold" : "text-blue-200 "
                )
              }
            >
              WITHDRAWALS
            </Tab>

            <Tab
              className={({ selected }) =>
                classNames(
                  "rounded-lg py-2.5 pl-5 pr-5 text-sm font-bold leading-5 text-[#3754A5]",
                  "ring-[#FBC1BF] ring-opacity-60 ring-offset-2  focus:outline-none focus:ring-0",
                  selected ? "bg-[#FBC1BF] font-semibold" : "text-blue-200 "
                )
              }
            >
              OTHERS
            </Tab>
          </Tab.List>
          <Tab.Panels className="mt-2">
            <Tab.Panel>
              <div className="relative overflow-x-auto  w-fill">
                <div className="pb-4 flex">
                  <button
                    className="border flex  items-center border-kera text-gray-700 py-2 px-8 rounded tracking-wide mr-4
                                font-semibold font-display focus:outline-none focus:shadow-outline"
                  >
                    <svg
                      className="mr-4"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.4"
                        d="M8.40282 13.2988H2.92322C2.22971 13.2988 1.66675 13.8519 1.66675 14.5333C1.66675 15.2137 2.22971 15.7677 2.92322 15.7677H8.40282C9.09634 15.7677 9.6593 15.2137 9.6593 14.5333C9.6593 13.8519 9.09634 13.2988 8.40282 13.2988Z"
                        fill="#3754A5"
                      />
                      <path
                        opacity="0.4"
                        d="M18.3334 5.31556C18.3334 4.63512 17.7705 4.08203 17.0779 4.08203H11.5983C10.9048 4.08203 10.3418 4.63512 10.3418 5.31556C10.3418 5.99691 10.9048 6.55 11.5983 6.55H17.0779C17.7705 6.55 18.3334 5.99691 18.3334 5.31556Z"
                        fill="#3754A5"
                      />
                      <path
                        d="M7.3982 5.31547C7.3982 6.87102 6.1158 8.13184 4.53247 8.13184C2.95007 8.13184 1.66675 6.87102 1.66675 5.31547C1.66675 3.76082 2.95007 2.5 4.53247 2.5C6.1158 2.5 7.3982 3.76082 7.3982 5.31547Z"
                        fill="#3754A5"
                      />
                      <path
                        d="M18.3335 14.499C18.3335 16.0536 17.0511 17.3145 15.4678 17.3145C13.8854 17.3145 12.6021 16.0536 12.6021 14.499C12.6021 12.9434 13.8854 11.6826 15.4678 11.6826C17.0511 11.6826 18.3335 12.9434 18.3335 14.499Z"
                        fill="#3754A5"
                      />
                    </svg>
                    Filter
                  </button>

                  <div className="relative mt-1 grow">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        className="w-5 h-5 text-gray-500 "
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="table-search"
                      className=" border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  "
                      placeholder="Search for items"
                    />
                  </div>
                </div>
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-100">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        AMOUNT
                      </th>
                      <th scope="col" className="px-6 py-3">
                        PAYMENT METHOD
                      </th>
                      <th scope="col" className="px-6 py-3">
                        STATUS
                      </th>
                      <th scope="col" className="px-6 py-3">
                        DATE CREATED
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-white border-b  ">
                      <td className="px-6 py-4">{dbState?.depAmount}</td>
                      <td className="px-6 py-4">{dbState?.depMethod}</td>
                      <td className="px-6 py-4">{dbState?.depStatus}</td>
                      <td className="px-6 py-4">{dbState?.depDate}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Tab.Panel>

            <Tab.Panel>
              <div className="relative overflow-x-auto  w-fill">
                <div className="pb-4 flex">
                  <button
                    className="border flex  items-center border-kera text-gray-700 py-2 px-8 rounded tracking-wide mr-4
                                font-semibold font-display focus:outline-none focus:shadow-outline  
                                 "
                  >
                    <svg
                      className="mr-4"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.4"
                        d="M8.40282 13.2988H2.92322C2.22971 13.2988 1.66675 13.8519 1.66675 14.5333C1.66675 15.2137 2.22971 15.7677 2.92322 15.7677H8.40282C9.09634 15.7677 9.6593 15.2137 9.6593 14.5333C9.6593 13.8519 9.09634 13.2988 8.40282 13.2988Z"
                        fill="#3754A5"
                      />
                      <path
                        opacity="0.4"
                        d="M18.3334 5.31556C18.3334 4.63512 17.7705 4.08203 17.0779 4.08203H11.5983C10.9048 4.08203 10.3418 4.63512 10.3418 5.31556C10.3418 5.99691 10.9048 6.55 11.5983 6.55H17.0779C17.7705 6.55 18.3334 5.99691 18.3334 5.31556Z"
                        fill="#3754A5"
                      />
                      <path
                        d="M7.3982 5.31547C7.3982 6.87102 6.1158 8.13184 4.53247 8.13184C2.95007 8.13184 1.66675 6.87102 1.66675 5.31547C1.66675 3.76082 2.95007 2.5 4.53247 2.5C6.1158 2.5 7.3982 3.76082 7.3982 5.31547Z"
                        fill="#3754A5"
                      />
                      <path
                        d="M18.3335 14.499C18.3335 16.0536 17.0511 17.3145 15.4678 17.3145C13.8854 17.3145 12.6021 16.0536 12.6021 14.499C12.6021 12.9434 13.8854 11.6826 15.4678 11.6826C17.0511 11.6826 18.3335 12.9434 18.3335 14.499Z"
                        fill="#3754A5"
                      />
                    </svg>
                    Filter
                  </button>

                  <div className="relative mt-1 grow">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        className="w-5 h-5 text-gray-500 "
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="table-search"
                      className=" border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  "
                      placeholder="Search for items"
                    />
                  </div>
                </div>
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-100">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        AMOUNT REQUESTED
                      </th>
                      <th scope="col" className="px-6 py-3">
                        AMOUNT + CHARGES
                      </th>
                      <th scope="col" className="px-6 py-3">
                        RECIEVING MODE
                      </th>
                      <th scope="col" className="px-6 py-3">
                        STATUS
                      </th>
                      <th scope="col" className="px-6 py-3">
                        DATE CREATED
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-white border-b  ">
                      <td className="px-6 py-4">{dbState?.wrAmount}</td>
                      <td className="px-6 py-4">{dbState?.wrCharges}</td>
                      <td className="px-6 py-4">{dbState?.wrMode}</td>
                      <td className="px-6 py-4">{dbState?.wrStatus}</td>
                      <td className="px-6 py-4">{dbState?.wrDate}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="relative overflow-x-auto  w-fill">
                <div className="pb-4 flex">
                  <button
                    className="border flex  items-center border-kera text-gray-700 py-2 px-8 rounded tracking-wide mr-4
                                font-semibold font-display focus:outline-none focus:shadow-outline"
                  >
                    <svg
                      className="mr-4"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.4"
                        d="M8.40282 13.2988H2.92322C2.22971 13.2988 1.66675 13.8519 1.66675 14.5333C1.66675 15.2137 2.22971 15.7677 2.92322 15.7677H8.40282C9.09634 15.7677 9.6593 15.2137 9.6593 14.5333C9.6593 13.8519 9.09634 13.2988 8.40282 13.2988Z"
                        fill="#3754A5"
                      />
                      <path
                        opacity="0.4"
                        d="M18.3334 5.31556C18.3334 4.63512 17.7705 4.08203 17.0779 4.08203H11.5983C10.9048 4.08203 10.3418 4.63512 10.3418 5.31556C10.3418 5.99691 10.9048 6.55 11.5983 6.55H17.0779C17.7705 6.55 18.3334 5.99691 18.3334 5.31556Z"
                        fill="#3754A5"
                      />
                      <path
                        d="M7.3982 5.31547C7.3982 6.87102 6.1158 8.13184 4.53247 8.13184C2.95007 8.13184 1.66675 6.87102 1.66675 5.31547C1.66675 3.76082 2.95007 2.5 4.53247 2.5C6.1158 2.5 7.3982 3.76082 7.3982 5.31547Z"
                        fill="#3754A5"
                      />
                      <path
                        d="M18.3335 14.499C18.3335 16.0536 17.0511 17.3145 15.4678 17.3145C13.8854 17.3145 12.6021 16.0536 12.6021 14.499C12.6021 12.9434 13.8854 11.6826 15.4678 11.6826C17.0511 11.6826 18.3335 12.9434 18.3335 14.499Z"
                        fill="#3754A5"
                      />
                    </svg>
                    Filter
                  </button>

                  <div className="relative mt-1 grow">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        className="w-5 h-5 text-gray-500 "
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="table-search"
                      className=" border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  "
                      placeholder="Search for items"
                    />
                  </div>
                </div>
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-100">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        AMOUNT
                      </th>
                      <th scope="col" className="px-6 py-3">
                        TYPE
                      </th>
                      <th scope="col" className="px-6 py-3">
                        PLAN/NARRATION
                      </th>
                      <th scope="col" className="px-6 py-3">
                        DATE CREATED
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-white border-b  ">
                      <td className="px-6 py-4">{dbState?.roAmount}</td>
                      <td className="px-6 py-4">{dbState?.roType}</td>
                      <td className="px-6 py-4">{dbState?.roPlan}</td>
                      <td className="px-6 py-4">{dbState?.roDate}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </Helmet>
  );
};

export default TransactionHistory;
