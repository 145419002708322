const firebaseConfig = {
  apiKey: "AIzaSyBexV4mL6HEwl7wWVEZpuDqrssiaIkMwHw",
  authDomain: "grandvilleglobaldb-65164.firebaseapp.com",
  projectId: "grandvilleglobaldb-65164",
  storageBucket: "grandvilleglobaldb-65164.appspot.com",
  messagingSenderId: "106070988973",
  appId: "1:106070988973:web:0d40aa2db654aa28e50e24",
  measurementId: "G-HKEQE2D447",
};

export default firebaseConfig;
