import React from "react";

import { PhoneIcon } from "@heroicons/react/outline";
import { CubeTransparentIcon, SupportIcon } from "@heroicons/react/solid";

import targetImg from "../../../assets/all-images/target.jpg";
import customerImg from "../../../assets/all-images/customers.jpg";
import solutionImg from "../../../assets/all-images/solution.jpg";

const Services = () => {
  return (
    <div name="support" className="w-full bg-zinc-500 bg-auto  mt-24">
      <div className="max-w-[1240px] mx-auto text-white relative">
        <div className="px-4 py-12">
          <p className="py-4 pt-5 text-2xl text-[#F2BA65]">
            Founded with the vision to create a platform that's 100%
            transparent. Our company is interested in investors who sieze
            opportunities and take risks to invest in crypto currencies based on
            our analysis provided by our expert traders We make sure that you
            get the your money's worth on each investment you make with our
            company So whether you are new to cryptocurrency trading or you’re
            just starting out, you are in the right place.
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 relative gap-x-8 gap-y-16 px-4 pt-12 sm:pt-20 text-black">
          <div className="bg-white rounded-xl shadow-2xl">
            <div className="p-8">
              <PhoneIcon className="w-16 p-4 bg-indigo-600 text-white rounded-lg mt-[-4rem]" />
              <h3 className="font-bold text-2xl my-6">
                Excellent Customer Support
              </h3>
              <img className="w-80 h-50" src={customerImg} alt="/" />
            </div>
            <div className="bg-slate-100 pl-8 py-4"></div>
          </div>
          <div className="bg-white rounded-xl shadow-2xl">
            <div className="p-8">
              <SupportIcon className="w-16 p-4 bg-indigo-600 text-white rounded-lg mt-[-4rem]" />
              <h3 className="font-bold text-2xl my-6">Solution Oriented</h3>
              <img className="w-80 h-50" src={solutionImg} alt="/" />
            </div>
            <div className="bg-slate-100 pl-8 py-4"></div>
          </div>
          <div className="bg-white rounded-xl shadow-2xl">
            <div className="p-8">
              <CubeTransparentIcon className="w-16 p-4 bg-indigo-600 text-white rounded-lg mt-[-4rem]" />
              <h3 className="font-bold text-2xl my-6">Profit Target</h3>
              <img className="w-80 h-50" src={targetImg} alt="/" />
            </div>
            <div className="bg-slate-100 pl-8 py-4"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
