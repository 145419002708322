import React from "react";

import styled from "styled-components";
import Prices from "../components/UI/PricingInvest";

const Pricing = () => {
  return (
    <div className="MainDash">
      <MainContainer>
        <div className="pricing-component">
          <Prices
            data={[
              { text: "Minimum Deposit: $500", value: true },
              { text: "Maximum Deposit: $4999", value: true },
              { text: "Minimum Returns: $10", value: true },
              { text: "Maximum Returns: $99.98", value: true },
            ]}
            price={500}
            duration="daily"
            currency="$"
            buttonContent="Invest"
            subTitle="2% ROI"
            priceText="Investment Plan"
            headerText="BEGINNER PLAN"
          />
        </div>
        <div className="pricing-component">
          <Prices
            data={[
              { text: "Minimum Deposit: $5000", value: true },
              { text: "Maximum Deposit: $9999", value: true },
              { text: "Minimum Returns: $150", value: true },
              { text: "Maximum Returns: $299.97", value: true },
            ]}
            price={5000}
            duration="daily"
            background="linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%)"
            shadow="#96e6a1"
            currency="$"
            buttonContent="Invest"
            subTitle="3% ROI"
            priceText="Investment Plan"
            headerText="BASIC PLAN"
          />
        </div>
        <div className="pricing-component">
          <Prices
            data={[
              { text: "Minimum Deposit: $1000", value: true },
              { text: "Maximum Deposit: $24999", value: true },
              { text: "Minimum Returns: $400", value: true },
              { text: "Maximum Returns: $999.96", value: true },
            ]}
            price={10000}
            duration="daily"
            background="linear-gradient(to left, #ff0844 0%, #ffb199 100%);"
            shadow="#ffb199"
            currency="$"
            buttonContent="Invest"
            subTitle="4% ROI"
            priceText="Investment Plan"
            headerText="PROFESSIONAL PLAN"
          />
        </div>
      </MainContainer>

      <MainContainer>
        <div className="pricing-component">
          <Prices
            data={[
              { text: "Minimum Deposit: $25000", value: true },
              { text: "Maximum Deposit: $49999", value: true },
              { text: "Minimum Returns: $1250", value: true },
              { text: "Maximum Returns: $299.95", value: true },
            ]}
            price={25000}
            duration="daily"
            currency="$"
            buttonContent="Invest"
            subTitle="5% ROI"
            priceText="Investment Plan"
            headerText="GRAND PLAN"
          />
        </div>
        <div className="pricing-component">
          <Prices
            data={[
              { text: "Minimum Deposit: $50000", value: true },
              { text: "Maximum Deposit: $99999", value: true },
              { text: "Minimum Returns: $3500", value: true },
              { text: "Maximum Returns: $6999.97", value: true },
            ]}
            price={50000}
            duration="daily"
            background="linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%)"
            shadow="#96e6a1"
            currency="$"
            buttonContent="Invest"
            subTitle="7% ROI"
            priceText="Investment Plan"
            headerText="MASTER PLAN"
          />
        </div>
        <div className="pricing-component">
          <Prices
            data={[
              { text: "Minimum Deposit: $100000", value: true },
              { text: "Maximum Deposit: $500000", value: true },
              { text: "Minimum Returns: $10000", value: true },
              { text: "Maximum Returns: $50000", value: true },
            ]}
            price={100000}
            duration="y"
            background="linear-gradient(to left, #ff0844 0%, #ffb199 100%);"
            shadow="#ffb199"
            currency="$"
            buttonContent="Invest"
            subTitle="10% ROI"
            priceText="Investment Plan"
            headerText="DIAMOND PLAN"
          />
        </div>
      </MainContainer>

      <MainContainer>
        <div className="pricing-component">
          <Prices
            data={[
              { text: "Minimum Deposit: $300", value: true },
              { text: "Maximum Deposit: $2999", value: true },
              { text: "Minimum Returns: $30", value: true },
              { text: "Maximum Returns: $299.8", value: true },
            ]}
            price={300}
            duration="Weekly"
            currency="$"
            buttonContent="Invest"
            subTitle="10% ROI"
            priceText="Investment Plan"
            headerText="STARTER"
          />
        </div>
        <div className="pricing-component">
          <Prices
            data={[
              { text: "Minimum Deposit: $3000", value: true },
              { text: "Maximum Deposit: $6999", value: true },
              { text: "Minimum Returns: $420", value: true },
              { text: "Maximum Returns: $979.85", value: true },
            ]}
            price={3000}
            duration="Weekly"
            background="linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%)"
            shadow="#96e6a1"
            currency="$"
            buttonContent="Invest"
            subTitle="14% ROI"
            priceText="Investment Plan"
            headerText="STANDARD"
          />
        </div>
        <div className="pricing-component">
          <Prices
            data={[
              { text: "Minimum Deposit: $7000", value: true },
              { text: "Maximum Deposit: $14999", value: true },
              { text: "Minimum Returns: $1260", value: true },
              { text: "Maximum Returns: $2699.86", value: true },
            ]}
            price={7000}
            duration="Weekly"
            background="linear-gradient(to left, #ff0844 0%, #ffb199 100%);"
            shadow="#ffb199"
            currency="$"
            buttonContent="Invest"
            subTitle="18% ROI"
            priceText="Investment Plan"
            headerText="PROFESSIONAL"
          />
        </div>
      </MainContainer>

      <MainContainer>
        <div className="pricing-component">
          <Prices
            data={[
              { text: "Minimum Deposit: $15000", value: true },
              { text: "Maximum Deposit: $29999", value: true },
              { text: "Minimum Returns: $3450", value: true },
              { text: "Maximum Returns: $6899.95", value: true },
            ]}
            price={15000}
            duration="Weekly"
            currency="$"
            buttonContent="Invest"
            subTitle="23% ROI"
            priceText="Investment Plan"
            headerText="PREMIUM"
          />
        </div>
        <div className="pricing-component">
          <Prices
            data={[
              { text: "Minimum Deposit: $30000", value: true },
              { text: "Maximum Deposit: $49999", value: true },
              { text: "Minimum Returns: $9000", value: true },
              { text: "Maximum Returns: $14999.87", value: true },
            ]}
            price={30000}
            duration="Weekly"
            background="linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%)"
            shadow="#96e6a1"
            currency="$"
            buttonContent="Invest"
            subTitle="30% ROI"
            priceText="Investment Plan"
            headerText="ELITE"
          />
        </div>
        <div className="pricing-component">
          <Prices
            data={[
              { text: "Minimum Deposit: $50000", value: true },
              { text: "Maximum Deposit: $150000", value: true },
              { text: "Minimum Returns: $20000", value: true },
              { text: "Maximum Returns: $60000", value: true },
            ]}
            price={50000}
            duration="Weekly"
            background="linear-gradient(to left, #ff0844 0%, #ffb199 100%);"
            shadow="#ffb199"
            currency="$"
            buttonContent="Invest"
            subTitle="40% ROI"
            priceText="Investment Plan"
            headerText="DELUXE"
          />
        </div>
      </MainContainer>
    </div>
  );
};

const MainContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #f5f1ff;
  @media screen and (max-width: 970px) {
    height: 100%;
    flex-direction: column;
    .pricing-component {
      margin: 2rem 0;
    }
  }
`;

export default Pricing;
